import { Suspense, cloneElement, useEffect, useState } from 'react'
import ringer from "./modularbg.mp3"


function Ready({ setReady }) {
  useEffect(() => () => void setReady(true), [])
  return null
}

export default function Intro({ children }) {
  const [clicked, setClicked] = useState(false)
  const [ready, setReady] = useState(false)
  const audio = new Audio(ringer);
  audio.loop = true;
  audio.volume = 0.1;
  return (
    <>
      <Suspense fallback={<Ready setReady={setReady} />}>
      {cloneElement(children, { ready: clicked && ready })}
        <div className={`start bg ${ready ? 'ready' : 'notready'} ${clicked && 'clicked'}`}>
          <div className="stack">
            <a href="#" onClick={() => {
              audio.loop = true;
              audio.play();
              setClicked(true)}}>
              {!ready ? 'loading' : 'START'}
            </a>
          </div>
        </div>
      </Suspense>
    </>
  )
}
