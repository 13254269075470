import { Physics, Debug } from '@react-three/rapier'
import { Level } from './Level.js'
import Effects from './Effects.js'
import { RGBELoader } from 'three-stdlib'
import { useLoader } from '@react-three/fiber'
import {
  useGLTF,
  Center,
  Text3D,
  Instance,
  Instances,
  Environment,
  Lightformer,
  OrbitControls,
  RandomizedLight,
  AccumulativeShadows,
  MeshTransmissionMaterial
} from '@react-three/drei'
import { useControls, button } from 'leva'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'


const Grid = ({ number = 23, lineWidth = 0.026, height = 0.5 }) => (
    // Renders a grid and crosses as instances
    <Instances position={[0, -1, 0]}>
      <planeGeometry args={[lineWidth, height]} />
      <meshBasicMaterial color="#000000" />
      {Array.from({ length: number }, (_, y) =>
        Array.from({ length: number }, (_, x) => (
          <group key={x + ':' + y} position={[x * 2 - Math.floor(number / 2) * 2, -0.01, y * 2 - Math.floor(number / 2) * 2]}>
            <Instance rotation={[-Math.PI / 2, 0, 0]} />
            <Instance rotation={[-Math.PI / 2, 0, Math.PI / 2]} />
          </group>
        ))
      )}
      <gridHelper args={[100, 100, '#000000', '#000000']} position={[0, -0.01, 0]} />
    </Instances>
  )

export default function Experience({ children, ...props })
{


    return <>
      <>
        <group rotation={[0, Math.PI / 1, 0]} position={[0, 1.1, 0]}>
          {/* <Center scale={[0.8, 1, 1]} front top {...props}>
          </Center> */}
          <Grid />
        </group>
        <Level />
      </>
        {/* <Effects /> */}
    </>
}